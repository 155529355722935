<template>
    <div v-show="canView" :class="classes">
        <slot />
    </div>
</template>

<script>
import Store from "@/store";

export default {
    props: {
        permissions: {
            type: [Array],
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            baseClasses: "space-y-1",
        };
    },
    computed: {
        classes() {
            return `form-group ${this.baseClasses}`;
        },
        canView() {
            if (this.permissions.length > 0) {
                return this.hasPermission(this.permissions);
            }

            return true;
        },
    },
    methods: {
        hasPermission(permissions) {
            let hasPermissions = true;
            permissions.forEach((permission) => {
                const hasPermission = Store.getters["authModule/hasPermission"](permission);

                if (!hasPermission) {
                    hasPermissions = false;
                }
            });

            return hasPermissions;
        },
    },
};
</script>
